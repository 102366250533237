<template>
  <div class="m-divider m-padding">
    <p></p>
    <div :style="{color: color }">{{title}}</div>
    <p></p>
  </div>
</template>

<script>
export default {
  name:"MDivider",
  props:['title','color']
}
</script>

<style lang="less" scoped>
.m-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  &>p {
    flex: 1;
    height: 1px;
    background: #F0F0F0;
  }
  &>div {
    font-size:18px;
    font-weight:500;
    color:rgba(51,51,51,1);
    line-height:25px;
    margin: 0 22px;
  }
}
</style>